/**
 * @category  Scandiweb
 * @package   ScandiPWA/AlternativeInfiniteLoading
 * @author    Ramona Cunska <info@scandiweb.com> on (ScandiPWA/CatalogLoadMore package)
 */

export class ProductListPageComponentPlugin {
    /**
     * Extended to remove product placeholders unless page is loading.
     * Only displaying Offset element which is observed and used to
     * determine when the next product request should be made.
     */
    renderPlaceholderItems = (args, callback, instance) => {
        const { isLoading } = instance.props;

        if (!isLoading) {
            return (
                <li
                  block="ProductListPage"
                  elem="Offset"
                  ref={ instance.getPlaceholderRef() }
                />
            );
        }

        return callback.apply(instance, args);
    };
}

const {
    renderPlaceholderItems
} = new ProductListPageComponentPlugin();

export default {
    'Component/ProductListPage/Component': {
        'member-function': {
            renderPlaceholderItems
        }
    }
};
