/**
 * @category  Scandiweb
 * @package   ScandiPWA/AlternativeInfiniteLoading
 * @author    Ramona Cunska <info@scandiweb.com> on (ScandiPWA/CatalogLoadMore package)
 * @author    Erick Lima <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React from 'react';

import Loader from 'Component/Loader';

import './ProductListComponent.style.scss';

export class ProductListComponentPlugin {
    /**
     * Extended to add additional props to component.
     */
    propTypes = (args) => ({
        ...args,
        isPageLoading: PropTypes.bool.isRequired,
        isNextPagePreloaded: PropTypes.bool.isRequired,
        shouldRenderLoaderOverlay: PropTypes.bool.isRequired,
        nextPageCount: PropTypes.number.isRequired,
        loadNextPageProducts: PropTypes.func.isRequired
    });

    /**
     * We need to remove page scroll-up for desktop devices when load more is enabled
     * therefore we can't call the callback method.
     */
    componentDidUpdate = (args, callback, instance) => {
        const {
            isInfiniteLoaderEnabled
        } = instance.props;

        if (isInfiniteLoaderEnabled) {
            instance.observePageChange();

            return;
        }

        callback.apply(instance, args);
    };

    /**
     * Extended to remove pagination with "Infinite loading" loader overlay
     */
    renderPagination = (args, callback, instance) => {
        const { isInfiniteLoaderEnabled } = instance.props;

        if (isInfiniteLoaderEnabled) {
            return this.renderPageLoadingLoader.apply(instance);
        }

        return callback.apply(instance, args);
    };

    /**
     * Extended to remove the last page from display in case if it is preloaded and "load more"
     * button was not yet clicked, and to adjust placeholder display.
     */
    renderPages = (args, callback, instance) => {
        const {
            pages,
            isVisible,
            isLoading,
            isPageLoading,
            isNextPagePreloaded,
            isInfiniteLoaderEnabled
        } = instance.props;

        if (!isInfiniteLoaderEnabled || isLoading) {
            return callback.apply(instance, args);
        }

        const visiblePages = Object.entries(pages);

        if (isNextPagePreloaded && !isPageLoading) {
            visiblePages.splice(-1);
        }

        const pageRenders = visiblePages.map(instance.renderProductPage.bind(instance));

        /**
         * Add placeholders to the end of pages if needed.
         * Placeholders contain the element that is observed and calls next page preloading
         * so we don't want to display it if the next page is already preloaded or loading.
        */
        if (isVisible && isInfiniteLoaderEnabled && !isNextPagePreloaded && !isPageLoading) {
            const key = Math.max(...Object.keys(pages)) + 1;
            pageRenders.push(instance.renderPage({ key }));
        }

        return pageRenders;
    };

    renderPageLoadingLoader() {
        const {
            shouldRenderLoaderOverlay,
            nextPageCount,
            isInfiniteLoaderEnabled
        } = this.props;

        if (!isInfiniteLoaderEnabled || nextPageCount < 1) {
            return null;
        }

        return <Loader isLoading={ shouldRenderLoaderOverlay } />;

        // Do we need to generate a link address to the next page for the href property so that
        // all products can be accessible by bots even when the page loads automatically?
        // // import { getNextPageUrl } from '../util/NextPageUrl';
        // const nextPage = getNextPageUrl(location);

        // return (
        //     <div
        //       block="ProductList"
        //       elem="LoadMoreButtonWrapper"
        //       // eslint-disable-next-line react/style-prop-object
        //       style={ linkDisplay }
        //     >
        //         <span
        //           block="ProductList"
        //           elem="LoadMoreButton"
        //           mix={ { block: 'Button' } }
        //           onClick={ loadNextPageProducts }
        //           href={ nextPage }
        //         >
        //             { label }
        //         </span>
        //     </div>
        // );
    }
}

const pluginObj = new ProductListComponentPlugin();

const {
    componentDidUpdate,
    renderPagination,
    renderPages
} = pluginObj;

const trackScrolling = (isInfiniteLoaderEnabled, loadNextPageProducts) => {
    if (!isInfiniteLoaderEnabled) {
        return;
    }

    const scrollPosition = window.innerHeight + window.pageYOffset;
    const pageHeight = document.documentElement.scrollHeight;
    const offset = 200;

    if (scrollPosition + offset >= pageHeight) {
        loadNextPageProducts();
    }
};

const componentDidMountPlugin = (args, callback, instance) => {
    const {
        loadNextPageProducts,
        isInfiniteLoaderEnabled
    } = instance.props;

    if (isInfiniteLoaderEnabled) {
        const scrollTrackerCallback = trackScrolling.bind(null, isInfiniteLoaderEnabled, loadNextPageProducts);
        document.addEventListener('scroll', scrollTrackerCallback);

        instance.setState({
            scrollTrackerCallback
        });
    }

    return callback.apply(instance, args);
};

const componentWillUnmountPlugin = (args, callback, instance) => {
    const ret = callback.apply(instance, args);

    const {
        isInfiniteLoaderEnabled
    } = instance.props;

    if (isInfiniteLoaderEnabled) {
        const scrollTrackerCallback = instance.state;
        document.removeEventListener('scroll', scrollTrackerCallback);
    }

    return ret;
};

export default {
    'Component/ProductList/Component': {
        'member-function': {
            componentDidMount: componentDidMountPlugin,
            componentWillUnmount: componentWillUnmountPlugin,
            componentDidUpdate: componentDidUpdate.bind(pluginObj),
            renderPagination: renderPagination.bind(pluginObj),
            renderPages: renderPages.bind(pluginObj)
        }
    }
};
